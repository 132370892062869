*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.header{
  padding: 1rem;
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0);
  color: white;
}

.header-titulo{
  padding-right: 15%;
  text-transform: uppercase;
  font-weight: 800;
}

.header-alumnos{
  padding-left: 15%;
}

body {
  font: 14px "Century Gothic", Futura, sans-serif;
  background: linear-gradient(to right, rgb(253, 191, 76), pink);
  height: 50%;
}

.game {
  display: flex;
  flex-direction: row;
  justify-conten: center;
  margin-left:5%;
  margin-right: 15%;
  padding-top:0rem;
}

.panelControl{
  justify-content: center;
  height: 660px;
}

.leftPanel {
  border: solid;
  border-color: black;
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.buttonsPanel {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;
  grid-gap: 15px;
  justify-content: center;
}

.colorBtn {
  height: 40px;
  width: 40px;
  border: none;
  cursor: pointer;
}

.colorBtn:hover {
  outline: none;
  border: 2px solid black;
}

.turnsPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  font-size: 20px;
  color: darkblue;
}

.turnsNum{
  margin-top: 10px;
  border: solid;
  border-color: black;
  padding: 0.5rem;
}

.board {
  margin: 20px;
  display: grid;
  grid-template-columns: repeat(14, 40px);
  grid-template-rows: repeat(14, 40px);
}

.capturados {
  border: solid;
  border-color: black;
  display:flex;
  align-items: center;
  color: rgb(0, 0, 0);
  flex-direction: column;
  margin: 20px;
 font-size: 20px;
}

.historial{
  align-items: center;
  display:flex;
  color: black;
  flex-direction: column;
  margin-top: 40px;
  font-size: 20px;
  margin: 20px;
  border: solid;
  border-color:black;
  padding: 1rem;
}

.celdaOrigen{
  border: 3px solid black;
}

.squaresHistorial{
  height: 20px;
  width: 60px;
  border: 1px solid black;
}

.capturadosNum{
  margin-top: 1rem;
  border: solid;
  border-color:black;
  padding:0.5rem;
}

.footer{
  margin-top: 1rem;
  background-color: rgb(0, 0, 0);
  color:white;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  position: fixed;
  left:0px;
  bottom:0px;
}

.estrategia{
  border: solid;
  border-color: black;
  align-items: center;
  display:flex;
  color: black;
  flex-direction: column;
  margin-top: 40px;
  font-size: 30px;
  margin: 20px;
}

.profundidadNum {
  margin-top: 8px;
  font-size: 30px;
  border: black;
}

.BotonAyuda{
  padding: 0.5rem;
  margin-top: 0.5rem;
}

.ayudaLab{
  align-items: stretch;
  display:flex;
  color: black;
  flex-direction: row;
  margin-top: 10px;
  font-size: 20px;
  margin: 10px;
  padding: 0.2rem;
}

.squaresAyuda{
  height: 20px;
  width: 20px;
  border: 1px solid black; 
}

.stateAyuda{
  justify-content: center;
  display: flex;
  grid-template-columns: auto auto auto;
}


.records{
  align-items: center;
  display:flex;
  color: black;
  flex-direction: column;
  margin-top: 20px;
  font-size: 15px;
  margin: 20px;
  border: solid;
  border-color:black;
  padding: 1rem;
}
